// jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;
// Stickyfill
window.Stickyfill = require('stickyfilljs');
// object-fit-images
import objectFitPolyfill from 'objectFitPolyfill';
// slick
import slick from "slick-carousel/slick/slick.min.js";
// input mask
import Inputmask from "inputmask";
// lazysizes
import 'lazysizes';
// magnific_popup
import magnificPopup from "magnific-popup/dist/jquery.magnific-popup.min.js";
// eventstouch
var loadTouchEvents = require('jquery-touch-events');
loadTouchEvents($);
// lightcase
require("lightcase");
// selectric
import selectric from "selectric";
// import validate from "jquery-validation";
require("jquery-validation");
 // spritespin
 require('spritespin');
// main
require('./main');
// order
require('./order');
// popup
require('./popup');
// form
require('./form');
