$(document).ready( function() {
    // Inputmask
    $('input[type=tel]').each(function() {
        $(this).keypress(function(e, a) {
            if ((e.which == 13) || (e.keyCode == 13)) {
                $(this).blur();
            }
            return true;
        });
    });

    Inputmask({
        mask: '+38 (999) 999-99-99',
        clearMaskOnLostFocus: true,
        clearIncomplete: true,
        showMaskOnHover: false,
    }).mask('input[type=tel]');


    // validate form callback
    $(".do_callback_form").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.callback_form');
        formCallbackValidate(formVal);
        formVal.submit();
    });
    function formCallbackValidate(form) {
        form.validate({
            submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $(form).serialize();
              $(form)[0].reset();
              $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "callback"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $.magnificPopup.close();
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( showForm ,5000);

                    if(!$(form).hasClass('callback-form')) {
                        setTimeout( hidePopup ,5000);
                    }
                }
              });
            }
        });
    }

    // validate form feedback
    $(".do-feedback-form").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.feedback-form');
        formFeedbackValidate(formVal);
        formVal.submit();
    });
    function formFeedbackValidate(form) {
        form.validate({
            submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $(form).serialize();
              $(form)[0].reset();
              $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "feedback"
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( showForm ,5000);
                }
              });
            }
        });
    }

    // quick form start
    $(".do-quick-order_form").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.quick-order_form');
        formQuickValidate(formVal);
        formVal.submit();
    });
    function formQuickValidate(form) {
        form.validate({
            submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $(form).serialize();
              $(form)[0].reset();
              $.ajax({
                url: routes.postFastOrder,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $.magnificPopup.close();
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,5000);
                    setTimeout( showForm ,5000);
                }
              });
            }
        });
    }

    // get_price start
    function formValidateGetPrice(form) {
        form.validate({
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "price_request"
                    },
                    success: function(data) {
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $.magnificPopup.close();
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,5000);
                        setTimeout( showForm ,5000);
                    }
                })
            }
        });
    };
    $(".do_get_price").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.get_price_form');
        formValidateGetPrice(formVal);
        formVal.submit();
    });
    // get_price end

    // get_good start
    function formValidateGetGood(form) {
        form.validate({
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "good_request"
                    },
                    success: function(data) {
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $.magnificPopup.close();
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,5000);
                        setTimeout( showForm ,5000);
                    }
                })
            }
        });
    };
    $(".do_get_good").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.get_good_form');
        formValidateGetGood(formVal);
        formVal.submit();
    });
    // get_good end

});
