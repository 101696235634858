function initLightcase() {
    $('a[data-rel="lightcase:gallery"]').lightcase({
        transition: 'scrollHorizontal',
        showSequenceInfo: true,
        showTitle: true,
        showCaption: true,
        shrinkFactor: 0.9,
        swipe: true,
        navigateEndless: false,
        maxWidth: '90%',
        maxHeight: '90%'
    });
}

$(document).ready(function() {
    // lightcase gallery
    if($('a[data-rel="lightcase:gallery"]').length) {
        initLightcase();
    }

    // selectric init
    $('.sort_wrap').selectric({
        disableOnMobile: false,
        nativeOnMobile: false,
    })

    $(".up_button").on("click", function(e) {
        e.stopPropagation();
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 450)
    });

    ///admin img
    var adminImg = document.querySelectorAll(".description");
    if (adminImg) {
        Array.prototype.forEach.call(adminImg, function (wrapper, i) {
            var images = wrapper.querySelectorAll("img");
            if (images) {
                Array.prototype.forEach.call(images, function (img, i) {
                    const float = img.style.float;
                    if (float == "left") img.classList.add("margin-left-none");
                    else if (float == "right") img.classList.add("margin-right-none");
                });
            }
        });
    };

    $(".js-lastcrumb").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
	})
    // polifil object-fit
    if($('.object-fit-js').length) {
       var someImages = $('.object-fit-js');
       objectFitPolyfill(someImages);
    }
    // position sticky
    if($('.sticky').length) {
        var elements = $('.sticky');
        Stickyfill.add(elements);
    }
    // for header
    $('.burger-menu').on('click', function() {
        $('.header-main').toggleClass('dark');
        $('.menu-icon').toggleClass('open');
        $('.header-bottom').toggleClass('open');
        $('body').toggleClass('overflow');
        $('html').toggleClass('not-overflow');
    });

    $(document).on( 'click', '.open-menu-navigation-js', function(e) {
        var submenu = $(this).closest('.header-menu-navigation').find('.header-menu-navigation-list');
        var icon =  $(this).closest('.header-menu-navigation').find('.header-menu-navigation-icon');
        $(this).toggleClass('active');
        if($(this).hasClass('active')) {
          icon.addClass('active');
          submenu.slideDown(200);
        } else {
          icon.removeClass('active');
          submenu.slideUp(200);
        }
    });

    $('.footer-name-js').on('click', function() {
        if (window.innerWidth < 1025 ) {
            var submenu = $(this).closest('.footer-item').find('.footer-item-nav');
            var icon =  $(this).closest('.footer-item').find('.footer-item-icon');
            $(this).toggleClass('active');
            if($(this).hasClass('active')) {
                icon.addClass('active');
                submenu.slideDown(200);
            } else {
                icon.removeClass('active');
                submenu.slideUp(200);
            }
        }
    });

    // sliders
    if($('.slider').length) {
        var photoSlider = document.querySelectorAll('.slider');
        $(photoSlider).each(function(){
            var $this = $(this),
                $arrowprev = $this.closest('.slider-wrap').find('.arrow-prev'),
                $arrownext = $this.closest('.slider-wrap').find('.arrow-next');
                $this.slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: $arrowprev,
                    nextArrow: $arrownext,
                    dots: false,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1441,
                            settings: {
                                arrows: false,
                                dots: true,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                                dots: true,
                            }
                        },
                        {
                            breakpoint: 577,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                dots: true,
                            }
                        }
                    ]
                })
        })
    }

    if($('.gallery-photo').length) {
        var photoSlider = document.querySelectorAll('.gallery-photo');
        $(photoSlider).each(function(){
            var $this = $(this),
                $arrowprev = $this.closest('.gallery-photo-wrap').find('.arrow-prev'),
                $arrownext = $this.closest('.gallery-photo-wrap').find('.arrow-next');
                $this.slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: $arrowprev,
                    nextArrow: $arrownext,
                    infinite: false,
                    dots: false,
                    responsive: [
                        {
                            breakpoint: 1025,
                            settings: {
                                dots: true,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                dots: true,
                            }
                        },
                        {
                            breakpoint: 577,
                            settings: {
                                slidesToShow: 1,
                                dots: true,
                            }
                        }
                    ]
                })
        })
    }
});

$(window).on('load scroll', function () {
    if ($(window).scrollTop() > 70) {
        $(".up_button").addClass("visible").fadeIn();
    } else {
        $(".up_button").removeClass("visible").fadeOut();
    }

    if (window.innerWidth > 1024) {
        if ($(window).scrollTop() > 122) {
            $('.header-bottom').addClass('header-fixed');
            $('.header').addClass('padding-bottom');
        } else {
            $('.header-bottom').removeClass('header-fixed');
            $('.header').removeClass('padding-bottom');
        }
    }
});

$(window).on('load resize', function() {
    if (window.innerWidth < 1025) {
        if ($('.header-bottom').hasClass('header-fixed')) {
            $('.header-bottom').removeClass('header-fixed');
            $('.header').removeClass('padding-bottom');
        }
    } else {
        // close mobile filter
        if ($('.catalogue-list-filters').hasClass('open')) {
            $('.js_filter_close_btn').trigger("click");
            $('body').removeClass('overflow');
            $('html').removeClass('not-overflow');
        }
        if ($('.header-bottom').hasClass('open')) {
            $('.burger-menu').trigger( "click" );
        }

        if ($('.footer-name-js').hasClass('active')) {
            $('.footer-name-js').removeClass('active');
            $('.footer-item-icon').removeClass('active');
            $('.footer-item-nav').slideDown(200);
        } else {
            $('.footer-item-nav').removeAttr("style");
        }
    }
});
