function reloadTopCart() {
    $.ajax({
        type: "POST",
        url: routes.postReloadTopCart,
        data: {},
        dataType: "json",
        success: function(data) {
            $("#top_cart").empty().html(data.top)
        }
    })
};

// function showEditOrder () {
//     $.ajax ({
//         type: "POST",
//         url: routes.postReloadOrderCart,
//         dataType: "json",
// 		success: function(data) {
//             $("#order_cart").empty().html(data.body);
// 		}
// 	});
// }

$(document).ready(function() {
    var lang = $('html').attr('lang');
    var ajax_url;
    $(document).on( 'click', '.js-to-cart', function(e) {
        var btn = $(this);
        var langInCart = btn.attr('data-in-cart');
        var good_id = $(this).attr('data-id');
        var count = $(this).attr('data-count');
        var el = $(this);
        ajax_url = routes.postReloadGoodOrder;
        $(".good-modal-wrapper").empty();
        console.log('кол-во', count);
        $.ajax ({
            type: "POST",
            url: ajax_url,
            data: {
                "good_id": good_id,
                "count": count,
                "lang": lang,
            },
            success: function(data) {
                reloadTopCart();
                $(".good-modal-wrapper").html(data);
            },
        });
    });



    $('.popup-js').magnificPopup({
		fixedContentPos: true,
        callbacks: {
          open: function() {
            if($.magnificPopup.instance.content.is('.mobile-callback')) {
              $.magnificPopup.instance.bgOverlay.addClass('sm-index');
            }
          },
          change: function() {
            $.magnificPopup.instance.bgOverlay.removeClass('sm-index');
          },
          close: function() {
            $.magnificPopup.instance.bgOverlay.removeClass('sm-index');
          }
        }
	});

    $('.popup-js').not('.mobile-callback-link, .good-buy-btn, .js-to-cart').click(function () {
        var popup = $(this).attr('href');
        setTimeout(function() {
            $(popup).find(".input-form")[0].focus();
        }, 300)
    });

    $('.good-quick-btn').click(function() {
        const goodId = $(this).attr('data-id');
        const goodCount = $(this).attr('data-count');
        // const goodPriceStr = $(this).attr('data-price');
        // const goodPrice = parseFloat(goodPriceStr .replace(/,/, '.'));
        // const fullPrice = goodCount * goodPrice;

        $('.quick-order_form').find('input[name=good_id]').val(goodId);
        $('.quick-order_form').find('input[name=amount]').val(goodCount);
        // $('#quick-order_form').find('input[name=price]').val(fullPrice);
        // $('#quick_order').find('.js_modal_price').text(fullPrice);
    })


    $(".to_request-js").click(function(e) {
        var popup = $(this).attr('href');
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var desc = $(this).attr('data-desc');

        $(popup).find('.popup-request-name').html(name);
        $(popup).find('.popup-request-desc').html(desc);
        $(popup).find('.value_id').val(id);
    });

    $('.close-modal-js').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $.magnificPopup.close();
    })
});
