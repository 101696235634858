$(document).ready(function() {
    var timeout;
    var lang = $('html').attr('lang');

    function reloadTopCart() {
        $.ajax({
            type: "POST",
            url: routes.postReloadTopCart,
            data: {},
            dataType: "json",
            success: function(data) {
                $("#top_cart").empty().html(data.top)
            }
        })
    };

    function removeCart (good_id) {
        $.ajax ({
            type: "POST",
            url: routes.postRemoveCart,
            data: {
                "good_id": good_id,
            },
            success: function(data) {
                window.location.reload(true);
            }
        });
    };

    function showEditOrder () {
        $.ajax ({
            type: "POST",
            url: routes.postReloadOrderCart,
            dataType: "json",
            success: function(data) {
                $("#order_cart").empty().html(data.body);
            }
        });
    }

    $(document).on( 'click', '.set_count-popup', function(e) {
    	var input = $(this).parent().find('input');
        var id = input.attr('data-id');
    	var value = $(this).attr('data-value');
    	if (value == 'up'){
    		var new_count = parseInt(input.val()) + 1;
    	} else {
    		if (parseInt(input.val()) > 1){
    			var new_count = parseInt(input.val()) - 1;
    		} else {
    			var new_count = 0;
    		}
    	}
        $('.popup-order_count-js[data-id="'+id+'"]').val(new_count);
        $('.popup-order_count-js[data-id="'+id+'"]').change();
        if ($('.good-page').length) {
            $('.order_count-js').val(new_count);
            $('.good-buy-btn').attr('data-count', new_count);
            $('.good-quick-btn').attr('data-count', new_count);
        }
    });

    $(document).on('change', '.popup-order_count-js', function() {
        var $el = $(this);
        var good_id = $el.attr('data-id');
        window.clearTimeout(timeout);
        timeout = window.setTimeout(function() {
            var qty = $el.val();
            if (qty == 0) {
                var btn = $('.in_cart[data-id="'+ good_id +'"]');
                var addcart = btn.attr('data-to-cart');
                var mainBtn = $('.red-btn[data-id="'+ good_id +'"]');
                mainBtn.text(addcart);
                btn.addClass('add-to-cart').removeClass('in_cart');
                removeCart(good_id);
                showEditOrder ();
            } else {
                $.ajax ({
                        type:"POST",
                        url:routes.postUpdateCart,
                        data: {
                            "good_id": good_id,
                            "count": qty,
                    },
                    dataType:"json",
                    success: function(data) {
                        reloadTopCart();
                        $(".js_cart_subtotal").html(data.subtotal);
                        if($('.order__page-content').length) {
                            $el.closest('.order__item').find('.cart-subtotal').html(data.subtotal);
                            $('#cart_all_total').html(data.total);
                            $("#cart_all_count").html(data.count);
                        }
                    }
                });
            }
        }, 300);
    });

    // cart page

    $(document).on('click', '.remove_cart', function(e) {
        e.stopPropagation();e.preventDefault();
        var rowid = $(this).attr('data-id');
        var $el = $(this).closest('tr');
        removeCart(rowid)
    });


    // ordering page

    $(".address_toggler").change(function (e) {
        let value = $(this).val();
        if ($(this).is(":checked")) {
            $(".address_variants").slideUp(400);
            $('.address_variants').find('.input-form').each(function() {
                $(this).prop('disabled', true);
            });
            $(`.address_variants[id="${value}"]`).find('.input-form').each(function() {
                $(this).prop('disabled', false);
            });
            var paymentsMethods = $(this).attr('data-payments').split(',');
            $('.payment_radio').prop('disabled', false);
            $('.payment_radio').each(function () {
                var value = $(this).attr('data-payment');
                if (paymentsMethods.indexOf(value) == -1) {
                    $(this).prop('disabled', true);
                    if($(this).is( ":checked" ) ){
                        $('[data-payment="' + paymentsMethods[0]  + '"]').prop('checked', true);
                    }
                }
            });
            $("#" + $(this).val()).slideDown(400);
        } else {
            $(`.address_variants[id="${value}"]`).find('.input-form').each(function() {
                $(this).prop('disabled', true);
            });
        }
        if(  $('[data-payment="bank_transfer"]').prop('disabled') ){
            $('.bank-block').slideUp(150);
        }
    });



    $(".address_toggler").trigger("change");

    $('.payment_radio').change(function (e) {
        if ($(this).attr('data-payment') == "bank_transfer") {
            $('.bank-block').slideDown(150);
            $('.bank-radio').removeAttr('disabled')
        } else {
            $('.bank-radio').attr('disabled', true)
            $('.bank-block').slideUp(150);
        }
    });
    $('.bank-radio').change(function (e) {
        if ($(this).attr('id') == "entity") {
            $('#faceless_items').slideDown(150);
            $('#faceless_items').find('.input-form').removeAttr('disabled');
        } else {
            $('#faceless_items').slideUp(150);
            $('#faceless_items').find('.input-form').attr('disabled', true);
        }
    });

    // checkbox handler

    $("#order_form").validate({
        submitHandler: function (form) {
            var formdata = $("#order_form").serialize();
            $("#order_form")[0].reset();
            $('#do_order_btn').addClass('hide');
            $('.ajax-loader').removeClass('hidden');
            doOrder(formdata);
        }
    });
    function doOrder(formdata) {
        $.ajax({
            url: routes.postDoOrder,
            type: 'POST',
            data: {
                "data": formdata,
                "lang": lang,
            },
            success: function () {
                window.location.href = '/stranica-blagodarnosti';
            },
            error: function () {
                window.location = "/";
            }
        });
    }

});
